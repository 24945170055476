<template>
  <section class="section section-hero">
    <div class="section-hero-bg">
      <section-title
        icon="icon-services"
        h2="Kész a design, de nem áll rendelkezésre elegendő kapacitás?
        <br class='d-none d-lg-block' />
        Rövid a határidő? A programozók minőségi kódot akarnak?"
        h2small="Sitebuild
      cégeknek, ügynökségeknek, szabadúszoknak, grafikusoknak"
        createdate=""
      />
    </div>
    <div class="section section-hero-body section-hero-services">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6 order-2 order-lg-1">
            <p class="h2-small">
              A webes fejlesztők között a sitebuilding egy „lenézett”
              munkafolyamat, pedig a jó sitebuild aranyat ér. Weboldalak, webes
              projektek sikeressége múlhat rajta.
            </p>

            <div class="box-contents">
              <h3 class="d-none d-lg-block">// Sitebuild készítés</h3>
              <div id="bovebben-a-bannerekrol"></div>
              <br />
              <router-link
                @click="hidden = !hidden"
                class="btn btn-link"
                to="#bovebben-a-bannerekrol"
              >
                <span># Bővebben a sitebuildről</span>
              </router-link>

              <router-link
                @click="hidden = !hidden"
                class="btn btn-link"
                to="#banner-meretek"
              >
                <span> # Leggyakoribb banner méretek </span>
              </router-link>

              <router-link
                @click="hidden = !hidden"
                class="btn btn-link"
                to="#technologiak"
              >
                <span> # Technológiák, amiket használunk </span>
              </router-link>

              <router-link
                @click="hidden = !hidden"
                class="btn btn-link btn-link-cta"
                to="#megrendeles"
              >
                <span># A szolgáltatás megrendelése</span>
              </router-link>
            </div>
          </div>
          <div class="col-12 col-lg-6 order-1 order-lg-2">
            <div class="col-6 col-lg-12 m-auto mb-3 mb-lg-0">
              <img
                src="@/assets/img/all/img-box-services-sitebuild-right.png"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-services"
    h2="Bővebben a sitebuildről"
    h2small="Alapfogalmak, definíciók, miért és mire jó ez az egész?"
    createdate=""
  />

  <section class="section section-body">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3>// Mi is az a sitebuild?</h3>
          <p class="lead">
            A webdesigner által, valamilyen grafikai programmal megtervezett és
            elkészített webfelületek megvalósítása HTML5, CSS3 segítségével. A
            jó sitebuild logikus felépítésű, az írott és iratlan szabályokhoz
            alkalmazkodik. A különböző eszközökön (leggyakrabban desktop,
            tablet, mobil) pontos megjelenést biztosít.
          </p>

          <h3>// Miért érdemes szakemberre bízni?</h3>
          <p class="lead">
            Egy oldal használhatósága, sebessége, fejleszthetősége múlhat a jó
            sitebuild alapokon. A jó szakember kapocs a designer és a fejlesztő
            között. Tisztában van a grafikusok szárnyaló kreativásaival és a
            programozók kódok iránti szeretetével.
            <br />
            Már a tervezésbe érdemes bevonni a sitebuildert, aki ötleteivel,
            javaslataival tudja segíteni a munkát és le tudja fektetni azokat a
            határokat amelyek szükségesek, hogy a külső erősítse a tartalmat.
            <br />
          </p>

          <h3>// Mire fontos figyelni?</h3>
          <p class="lead">
            MINDEN megálmodott design megvalósítható, azonban fontos szem előtt
            tartani, hogy ezt mennyire időigényes megvalósítani és mennyire
            fogja szolgálni az oldal célját.
          </p>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-services"
    h2="Technológiák, amiket használunk"
    h2small=""
    createdate=""
  />

  <section v-once class="section section-body section-body-icons">
    <div id="megrendeles"></div>
    <div class="container">
      <div class="row">
        <div class="col-6 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-html5.svg" alt="HTML5" />
          <h3 class="mt-2"># HTML</h3>
        </div>
        <div class="col-6 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-css3.svg" alt="CSS3" />
          <h3 class="mt-2"># CSS</h3>
        </div>
        <div class="col-6 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-scss.svg" alt="SCSS" />
          <h3 class="mt-2"># SCSS</h3>
        </div>
        <div class="col-6 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-bootstrap5.svg" alt="Bootstrap 5" />
          <h3 class="mt-2"># BOOTSTRAP</h3>
        </div>
        <div class="col-6 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-javascript.svg" alt="JavaScript" />
          <h3 class="mt-2"># JavaScript</h3>
        </div>
        <div class="col-6 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-vscode.svg" alt="VSCode" />
          <h3 class="mt-2"># VSCODE</h3>
        </div>
        <div class="col-6 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-sketch.svg" alt="Sketch" />
          <h3 class="mt-2"># Sketch</h3>
        </div>
        <div class="col-6 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-photoshop.svg" alt="Photoshop" />
          <h3 class="mt-2"># Photoshop</h3>
        </div>
        <div class="col-6 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-vuejs.svg" alt="VueJS" />
          <h3 class="mt-2"># VUEJS</h3>
        </div>
        <div class="col-6 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-firebase.svg" alt="Fireabase" />
          <h3 class="mt-2"># FIREBASE</h3>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-services"
    h2="Sitebuild készítési <br class='d-none d-lg-block'> irányárak, megrendelés"
    h2small="Kedvező árakkal, egyszerű fizetési megoldásokkal rendelheti meg <br class='d-none d-lg-block'> a kívánt bannerekhez"
    createdate=""
  />

  <section v-if="sent" class="section section-price-box mt-5 mb-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="lead text-center">
            Köszönjük a megkeresést, hamarosan jelentkezünk!
          </p>
        </div>

        <div class="col-12 text-center">
          <router-link
            @click="hidden = !hidden"
            class="btn pt-4 pb-4 mt-3 mb-5"
            to="/szolgaltatasaink/statikus-es-dinamikus-banner-keszites"
          >
            <span class="ps-5 pe-5">Új megrendelés? </span>
          </router-link>
        </div>
      </div>
    </div>
  </section>

  <section v-if="!sent" class="section section-price-box mt-5 mb-5">
    <div class="container">
      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice30"
            data-bs-target="#collapseprice30"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />
                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Oldalak száma (db)</h5>
                <p class="collapse show" id="collapseprice30">
                  Állítsa be a szükséges darabszámot!
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center w-100"
          >
            <h4 class="w-75">
              <input
                type="range"
                class="form-range"
                min="1"
                max="20"
                id="range"
                v-model="range"
                v-if="!showform"
              />
              {{ range }}
              <span>db</span>
            </h4>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice40"
            data-bs-target="#collapseprice40"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />
                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Módosítások száma (db)</h5>
                <p class="collapse show" id="collapseprice40">
                  A biztosított anyagokkal elkészült statikus kreatívokon
                  illetve a tesztként elkészült animációkon történő ingyenes
                  módosítások száma (1-3db).
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <h4>
              {{ modificiation }}
              <span>db</span>
            </h4>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice40"
            data-bs-target="#collapseprice40"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />
                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Kedvezmény kupon (%)</h5>
                <p class="collapse show" id="collapseprice40">
                  A biztosított kedvezmény (10%, 20%, 30%) kupon 90 napon belül
                  használható fel újabb termék vagy szolgáltatás megrendelése
                  esetén.
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <h4>
              {{ coupon }}
              <span>%</span>
            </h4>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice45"
            data-bs-target="#collapseprice45"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />
                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Határidő (nap)</h5>
                <p class="collapse show" id="collapseprice45">
                  A megfelelő darabszámú banner elkészítésének és átadásának
                  időtartama. Ha csökkenteni szeretne a határidőn kérjen egyedi
                  árajánlatot!
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <h4>
              {{ deadline }}
              <span>nap</span>
            </h4>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice90"
            data-bs-target="#collapseprice90"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />

                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Online konzultáció (igen/nem)</h5>
                <p class="collapse show" id="collapseprice90">
                  Ha úgy érzi, hogy szüksége van bővebb tájékoztatásra akkor a
                  válaszott online platformon bővebb tájékoztatást nyújtunk,
                  külön díj ellenében (+7.620 Ft), a banner készítéssel
                  kapcsolatban (30perc).
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <div v-if="!showform" class="form-check form-check-inline">
              <input
                class="btn-check"
                type="radio"
                name="inlineRadioOnline"
                id="option0-online"
                value="false"
                v-model="online"
              />
              <label class="btn pt-3 pb-3" for="option0-online">
                <span class="ps-3 ps-md-5 pe-3 pe-md-5"> nem </span>
              </label>
            </div>
            <div v-if="!showform" class="form-check form-check-inline">
              <input
                class="btn-check"
                type="radio"
                name="inlineRadioOnline"
                id="option1-online"
                value="true"
                v-model="online"
              />
              <label class="btn pt-3 pb-3" for="option1-online">
                <span class="ps-3 ps-md-5 pe-3 pe-md-5"> igen </span>
              </label>
            </div>

            <div v-if="showform">
              <h4 v-if="!online">nem</h4>
              <h4 v-if="online">igen</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice100"
            data-bs-target="#collapseprice100"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />
                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Irányár (Ft)</h5>
                <p class="collapse show" id="collapseprice100">
                  Az ár tájékoztató jellegű, függ a szöveges anyag
                  mennyiségétől, a használandó grafikai elemektől, a módosítások
                  számától, az animáció(k) bonyolultságától!
                  <br />
                  <strong>
                    A végleges ár meghatározása a részletek megismerése után
                    történik.
                  </strong>
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <div class="row">
              <div class="col-12">
                <h4 class="w-100 mt-2">
                  Irányár: {{ totalpricecalculate }} <span>Ft</span>
                </h4>
              </div>
              <div class="col-12">
                <h4 class="w-100 mt-2">
                  <small>
                    ({{ Math.round(totalpricecalculate / range) }}
                    <span>Ft/db)</span>
                  </small>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!showform" class="row equal-cols">
        <div class="col price-col price-col-1"></div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <button @click="showform = true" class="btn btn-send pt-4 pb-4">
              <span class="ps-4 pe-4 text-center">
                Banner készítés megrendelése
              </span>
            </button>
          </div>
        </div>
      </div>

      <div v-if="showform" class="row">
        <div class="d-none d-lg-block col-lg-2 price-col-1"></div>
        <div class="col-12 col-lg-8 price-col-1">
          <Form
            v-if="!sent"
            @submit="sendForm"
            :validation-schema="schema"
            v-slot="{ errors }"
            class="contact-us-form"
          >
            <div class="form-group">
              <label for="name">// Cégnév, kapcsolattartó</label>
              <Field hidden name="banners" :value="this.range" />
              <Field hidden name="creatives" :value="this.range2" />
              <Field hidden name="modificiation" :value="this.modificiation" />
              <Field hidden name="coupon" :value="this.coupon" />
              <Field hidden name="deadline" :value="this.deadline" />
              <Field hidden name="online" :value="this.online" />
              <Field hidden name="source" :value="this.source" />
              <Field hidden name="when" :value="this.when" />
              <Field hidden name="totalprice" :value="totalpricecalculate" />

              <Field
                name="name"
                as="input"
                placeholder="Kérjük, adja meg cégnevét és a kapcsolattartó nevét!"
                class="form-control"
                :class="{ 'is-invalid': errors.name }"
              />
              <div class="invalid-feedback">{{ errors.name }}</div>
            </div>

            <div class="form-group">
              <label for="email">// Email cím</label>
              <Field
                name="email"
                as="input"
                placeholder="Kérjük, adja meg email címét!"
                class="form-control"
                :class="{ 'is-invalid': errors.email }"
              />
              <div class="invalid-feedback">{{ errors.email }}</div>
            </div>

            <div class="form-group">
              <label for="phone_number">// Telefonszám</label>
              <Field
                name="phone_number"
                as="input"
                placeholder="Kérjük, adja meg telefonszámát!"
                class="form-control"
                :class="{ 'is-invalid': errors.phone_number }"
              />
              <div class="invalid-feedback">{{ errors.email }}</div>
            </div>

            <div class="form-group">
              <label for="when">// Mikor keressük telefonon?</label>
              <Field
                name="when"
                as="select"
                class="form-control"
                :class="{ 'is-invalid': errors.service }"
              >
                <option value="" disabled selected>
                  Kérjük, adja meg mikor kereshetjük telefonon?
                </option>
                <option value="9-12">9-12h</option>
                <option value="12-16">12-16h</option>
                <option value="Ne keressenek">
                  Nem kérek telefonos hívást
                </option>
              </Field>
              <div class="invalid-feedback">{{ errors.service }}</div>
            </div>

            <div class="form-group">
              <label for="message">// Üzenet</label>
              <Field
                name="message"
                as="textarea"
                placeholder="Üzenet"
                class="form-control form-message"
                value=""
                :class="{ 'is-invalid': errors.message }"
              />
              <ErrorMessage name="message" />
              <div class="invalid-feedback">{{ errors.message }}</div>
            </div>

            <div class="form-check">
              <Field
                name="accept"
                type="checkbox"
                id="accept"
                value="true"
                class="form-check-input custom-control-input"
                :class="{ 'is-invalid': errors.accept }"
              />
              <label for="accept" class="form-check-label custom-control-label">
                Megismertem és elfogadom az <br />
                <router-link
                  @click="hidden = !hidden"
                  class="btn-privacy-link"
                  to="/adatvedelmi-tajekoztato"
                >
                  <span>adatvédelmi nyilatkozatot</span>
                </router-link>
              </label>
            </div>

            <div class="form-group text-center mt-5">
              <div class="d-grid col-lg-8 m-auto">
                <button type="submit" class="btn pt-4 pb-4 mb-2">
                  <span>Üzenet elküldése</span>
                </button>
                Minden mező kitöltése kötelező. <br />
                A megadott adatokat csak és kizárólag a kapcsolatfelvételhez
                használjuk fel.
              </div>
            </div>
          </Form>
        </div>
        <div class="d-none d-lg-block col-lg-2 price-col-1"></div>
      </div>
    </div>
  </section>

  <SectionTovabbiSzolgaltatasaink actuale="2" />
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";
import SectionTovabbiSzolgaltatasaink from "../../components/SectionTovabbiSzolgaltatasaink.vue";

export default {
  components: {
    SectionTitle,
    SectionTovabbiSzolgaltatasaink,
    Form,
    Field,
  },
  data() {
    const schema = Yup.object().shape({
      name: Yup.string().required("A név kötelező!"),
      email: Yup.string()
        .required("Az email kötelező!")
        .email("Az email érvénytelen!"),
      phone_number: Yup.string().required("A telefonszám kötelező!"),
      when: Yup.string().required("Kötelező mező!"),
      message: Yup.string(),
      accept: Yup.string().required(
        "Fogadja el az adatkezelési irányelveinket!"
      ),
    });

    return {
      range: 1,
      range2: 0,
      price15: 7620,
      price610: 6350,
      price1120: 5080,
      price215: 7620,
      price2610: 5080,
      price21120: 3810,
      online: false,
      onlineprice: 7620,
      source: false,
      sourceprice: 25400,
      showform: false,
      schema,
      sent: false,
    };
  },

  computed: {
    modificiation() {
      let modificiationqty = 1;
      if (this.range > 3) modificiationqty = 2;
      if (this.range > 10) modificiationqty = 3;
      return modificiationqty;
    },

    coupon() {
      let couponqty = 10;
      if (this.range > 5) couponqty = 20;
      if (this.range > 10) couponqty = 30;
      return couponqty;
    },

    deadline() {
      let deadlineqty = 2;
      if (this.range > 5) deadlineqty = 3;
      if (this.range > 10) deadlineqty = 3;
      return deadlineqty;
    },

    totalpricecalculate() {
      let totalprice = 0;

      if (this.range <= 5) {
        totalprice += this.range * this.price15;
      }

      if (this.range2 > 0 && this.range2 <= 5) {
        totalprice += this.range2 * this.price215;
      }

      if (this.range > 5 && this.range <= 10) {
        totalprice += this.price610 * (this.range - 5) + 5 * this.price15;
      }

      if (this.range2 > 5 && this.range2 <= 10) {
        totalprice += this.price2610 * (this.range2 - 5) + 5 * this.price215;
      }

      if (this.range > 10) {
        totalprice +=
          this.price1120 * (this.range - 10) +
          5 * this.price15 +
          5 * this.price610;
      }

      if (this.range2 > 10) {
        totalprice +=
          this.price21120 * (this.range2 - 10) +
          5 * this.price215 +
          5 * this.price2610;
      }

      if (this.online === "true") totalprice += this.onlineprice;
      if (this.source === "true") totalprice += this.sourceprice;

      return totalprice;
    },
  },

  methods: {
    sendForm(e) {
      axios
        .post("https://arda.hu/api/send_form_email_banners.php", {
          name: e.name,
          email: e.email,
          phone_number: e.phone_number,
          when: e.when,
          message: e.message,
          accept: e.accept,
          banners: e.banners,
          creatives: e.creatives,
          modificiation: e.modificiation,
          coupon: e.coupon,
          deadline: e.deadline,
          online: e.online,
          totalprice: e.totalprice,
        })
        .then(() => {
          this.sent = true;
        });
    },
    onReset() {
      this.sent = false;
    },
  },
};
</script>
